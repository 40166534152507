// custom CSS styles
import "./src/style.css"

// Highlighting for code blocks
//import "prismjs/themes/prism.css"

import "./src/prismminimal.css"

// scroll bug fix
export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
  const currentPosition = getSavedScrollPosition(location);
  setTimeout(() => {
    window.scrollTo(...(currentPosition || [0, 0]));
  }, 0);
  return false;
};